.App {
  min-width: 350px;
  width: 100vw;
  max-width: 2500px;
  font-size: 0px;
  padding-bottom: 0px;
  min-height: 100vh;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: -200;
}

