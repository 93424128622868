.progress-container{
    background-color: white;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progress-title{
    color: #0fb0c5;
    font-size: calc(18px + 1vw);
    padding: 0;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    font-weight: 500;
}

.progress-body{
    text-align: center;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: calc(12px + 1vw);
    width: 100%;
}

.table-small{
    border-collapse: collapse;
    font-size: calc(8px + 1vw);
    width: 100%;
}

.table-small tr th{
    border: 1px solid #000000;
    color: #0fb0c5;
    font-size: calc(10px + 1vw);
    text-align: center;
    background-color: #dcdcdc;
}
  
.table-small tr td {
    border: 1px solid #000000;
    text-align: center;
    padding: 8px;
    font-size: calc(8px + 1vw);
}

