
body {
  margin: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Roboto', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

