
body {
  margin: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Roboto', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}


.App {
  min-width: 350px;
  width: 100vw;
  max-width: 2500px;
  font-size: 0px;
  padding-bottom: 0px;
  min-height: 100vh;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: -200;
}


.home-cont {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-cont img {
    align-content: first baseline;
    overflow: hidden;
    object-position: top;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: -100;
}


.header-cont {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 0px;
    height: 14vh;
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: rgb(0, 0, 0, 0.3);
    color: white; 
}

.header-cont-bg {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 0px;
    height: 14vh;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: white;
    color: #0fb0c5; 
}

.header {
    font-size: min(max(24px, 4vw), 80px);
    height: 90%;
    width: 90%;
    max-width: calc(0.9 * 2500px);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header-title {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 30%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    background-color: transparent;
}

.header-itm {
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: inherit;
    width: calc(100%/3);
}

.header-itm:hover .link{
    transition-duration: .5s;
    color: #84e4f0;
}

.link{
    color: inherit;
    text-decoration: none;
    font-size: 0.6em;
}


.about-container {
    margin: 0;
    text-align: left;
    width: 100%;
    min-width: 320px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}

.about-us {
    margin-top: max(14vh, 100px);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    width: 90%;
    background-color: #ffffff;
}

.about-section{
    margin-top: 20px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-title{
    width: 90%;
    color: #0fb0c5;
    font-size: calc(18px + 1vw);
    padding: 0;
}

.section-ul{
    width: 90%;
    margin: 0;
}

.section-body{
    width: 90%;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: calc(12px + 1vw);
}

.section-body ul{
    margin: 0;

}


.section-body a{
    color: #000000;
}

.section-body a:hover{
    color: #0fb0c5;
}



.square {
    text-align: center;
    background-color: white;
    display: flex;
    width: 40vw;
    max-width: 110vh;
    height: 25vw;
    max-height: 60vh;
    min-width: 300px;
    min-height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    background-color: #343434;
}

.cover-intro {
    margin: 0;
    padding: 0;
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0fb0c5;
}

.cover-intro img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.intro-head{
    width: 70%;
    font-size: calc(15px + 1vw);
}

.intro-desc{
    width: 90%;
    font-size: calc(10px + 1vw);
}
.projects-container{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 320px;
    padding-bottom: 60px;
}

.projects{
    margin-top: max(14vh, 100px);
    background-color: white;
    width: 90%;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.more-project {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: calc(18px + 2vw);
    color: #000000;
}

.more-link {
    margin-left: calc(10px + 1vw);
    color: #0fb0c5;
    transition: 0.3s;
}

.more-link:hover {
    color: #0a7c8b;;
}


.complete-container{
    background-color: white;
    width: 90%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.complete-title{
    color: #0fb0c5;
    font-size: calc(18px + 1vw);
    padding: 0;
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
    font-weight: 500;
}

.complete-body{
    text-align: center;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: calc(10px + 1vw);
    width: 100%;
}


.projects-table {
    min-width: calc(0.52 * 400px);
    border-collapse: collapse;
    font-size: calc(8px + 1vw);
    width: 100%;
}

.projects-table tr th{
    border: 1px solid #000000;
    color: #0fb0c5;
    font-size: calc(10px + 1vw);
    text-align: center;
    background-color: #dcdcdc;
}
  
.projects-table tr td {
    border: 1px solid #000000;
    text-align: center;
    padding: 8px;
    font-size: calc(8px + 1vw);
}

.projects-table tr:nth-child(0){
    background-color: #dcdcdc;
    color: rgb(0, 0, 0);
}

.projects-table tr{
    background-color: white;
}
.progress-container{
    background-color: white;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progress-title{
    color: #0fb0c5;
    font-size: calc(18px + 1vw);
    padding: 0;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    font-weight: 500;
}

.progress-body{
    text-align: center;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: calc(12px + 1vw);
    width: 100%;
}

.table-small{
    border-collapse: collapse;
    font-size: calc(8px + 1vw);
    width: 100%;
}

.table-small tr th{
    border: 1px solid #000000;
    color: #0fb0c5;
    font-size: calc(10px + 1vw);
    text-align: center;
    background-color: #dcdcdc;
}
  
.table-small tr td {
    border: 1px solid #000000;
    text-align: center;
    padding: 8px;
    font-size: calc(8px + 1vw);
}


.in {
  background-color: transparent;
  width: 100%;
  opacity: 0;
}

.out {
  background-color: transparent;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s;
}
