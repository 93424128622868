.in {
  background-color: transparent;
  width: 100%;
  opacity: 0;
}

.out {
  background-color: transparent;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s;
}