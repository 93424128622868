.projects-container{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    min-width: 320px;
    padding-bottom: 60px;
}

.projects{
    margin-top: max(14vh, 100px);
    background-color: white;
    width: 90%;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.more-project {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: calc(18px + 2vw);
    color: #000000;
}

.more-link {
    margin-left: calc(10px + 1vw);
    color: #0fb0c5;
    transition: 0.3s;
}

.more-link:hover {
    color: #0a7c8b;;
}

