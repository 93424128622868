.home-cont {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-cont img {
    align-content: first baseline;
    overflow: hidden;
    object-position: top;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: -100;
}

