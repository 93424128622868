.complete-container{
    background-color: white;
    width: 90%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.complete-title{
    color: #0fb0c5;
    font-size: calc(18px + 1vw);
    padding: 0;
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
    font-weight: 500;
}

.complete-body{
    text-align: center;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: calc(10px + 1vw);
    width: 100%;
}


.projects-table {
    min-width: calc(0.52 * 400px);
    border-collapse: collapse;
    font-size: calc(8px + 1vw);
    width: 100%;
}

.projects-table tr th{
    border: 1px solid #000000;
    color: #0fb0c5;
    font-size: calc(10px + 1vw);
    text-align: center;
    background-color: #dcdcdc;
}
  
.projects-table tr td {
    border: 1px solid #000000;
    text-align: center;
    padding: 8px;
    font-size: calc(8px + 1vw);
}

.projects-table tr:nth-child(0){
    background-color: #dcdcdc;
    color: rgb(0, 0, 0);
}

.projects-table tr{
    background-color: white;
}