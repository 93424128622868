.header-cont {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 0px;
    height: 14vh;
    min-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: rgb(0, 0, 0, 0.3);
    color: white; 
}

.header-cont-bg {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 0px;
    height: 14vh;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: white;
    color: #0fb0c5; 
}

.header {
    font-size: min(max(24px, 4vw), 80px);
    height: 90%;
    width: 90%;
    max-width: calc(0.9 * 2500px);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header-title {
    font-size: 1em;
    margin: 0;
    padding: 0;
    width: 30%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    background-color: transparent;
}

.header-itm {
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: inherit;
    width: calc(100%/3);
}

.header-itm:hover .link{
    transition-duration: .5s;
    color: #84e4f0;
}

.link{
    color: inherit;
    text-decoration: none;
    font-size: 0.6em;
}

