.about-container {
    margin: 0;
    text-align: left;
    width: 100%;
    min-width: 320px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}

.about-us {
    margin-top: max(14vh, 100px);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    width: 90%;
    background-color: #ffffff;
}

.about-section{
    margin-top: 20px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-title{
    width: 90%;
    color: #0fb0c5;
    font-size: calc(18px + 1vw);
    padding: 0;
}

.section-ul{
    width: 90%;
    margin: 0;
}

.section-body{
    width: 90%;
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: calc(12px + 1vw);
}

.section-body ul{
    margin: 0;

}


.section-body a{
    color: #000000;
}

.section-body a:hover{
    color: #0fb0c5;
}



.square {
    text-align: center;
    background-color: white;
    display: flex;
    width: 40vw;
    max-width: 110vh;
    height: 25vw;
    max-height: 60vh;
    min-width: 300px;
    min-height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    background-color: #343434;
}

.cover-intro {
    margin: 0;
    padding: 0;
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0fb0c5;
}

.cover-intro img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.intro-head{
    width: 70%;
    font-size: calc(15px + 1vw);
}

.intro-desc{
    width: 90%;
    font-size: calc(10px + 1vw);
}